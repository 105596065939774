import config from "../config/config";
import request from "./request";


async function addRecord(domainId,recordName, type,ttl,token) {
    let url = config.api.endpoint + "/api/record/add";
    return await request.Post(url, {
        domain_id: domainId,
        name: recordName,
        type:type,
        ttl:ttl,
    }, token);
}

async function deleteRecord(id,token){
    let url = config.api.endpoint + "/api/record/delete";
    return await request.Post(url, {
        filter:{
            id:[id]
        },
    }, token);
}

async function queryRecord(domainId,namePattern,recordId,recordType,limit,offset,token){
    let url = config.api.endpoint + "/api/record/query";
    return await request.Post(url, {
        filter:{
            id:recordId===0?null:recordId,
            name_pattern:namePattern===""?null:namePattern,
            type:recordType,
            domain_id:domainId,
        },
        limit: limit,
        offset: offset,
    },token);
}

async function updateRecord(id,ttl,forbidden,token){
    let url = config.api.endpoint + "/api/record/update";
    return await request.Post(url, {
        filter:{
            id:[id]
        },
        update:{
            ttl:ttl,
            forbidden:forbidden,
        }
    },token);
}

export default {
    addRecord,
    deleteRecord,
    queryRecord,
    updateRecord
};