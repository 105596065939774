import config from "../config/config";
import request from "./request";


async function addCert(domains, cert, key, token) {
    let url = config.api.endpoint + "/api/cert/add";
    return await request.Post(url, {
        related_domain: domains,
        cert_content: cert===""?null:cert,
        key_content: key===""?null:key
    }, token);
}

async function deleteCert(id, token) {
    let url = config.api.endpoint + "/api/cert/delete"
    return await request.Post(url, {
        filter:{
            Id:[id]
        },
    }, token);
}

async function applyCert(id, token) {
    let url = config.api.endpoint + "/api/cert/apply/" + id;
    return await request.Get(url, token);
}

async function queryCert(namePattern, limit, offset, token) {
    let url = config.api.endpoint + "/api/cert/query";
    return await request.Post(url, {
        filter:{
            related_domain_pattern:namePattern
        },
        limit: limit,
        offset: offset
    }, token);
}

async function updateCert(id, cert, key,tokens_map, token) {
    let url = config.api.endpoint + "/api/cert/update"
    return await request.Post(url, {
        filter:{
            Id:[id]
        },
        update:{
            cert_content: cert,
            key_content: key,
            tokens_map:tokens_map
        }
    }, token);
}

export default {
    addCert,
    deleteCert,
    queryCert,
    updateCert,
    applyCert
};