export default [
    {
        header: 'Pages',
        items: [
            {
                name: 'Welcome',
                icon: 'BoxIcon',
                menu_hidden: true,
                path: '/',
                component: () => import('../pages/welcome.vue'),
                meta:{
                    layout:'vertical',
                    auth: (islogin, roles, permissions) => {
                        return islogin;
                    }            
                }
            },
        ]
    },
    {
        header: 'Admin',
        items: [
            {
                name: 'user manager',
                icon: 'UsersIcon',
                path: '/admin/usermanager',
                component: () => import('../pages/admin/userMgr.vue'),
                meta:{
                    layout:'vertical',
                    auth: (islogin, roles, permissions) => {
                        return islogin && roles.includes('admin')
                    }           
                }
            },
            {
                icon:"DatabaseIcon",
                name: "Kv Manager",
                path: "/admin/kv_mgr",
                component: () => import("../pages/kv_mgr/kv_mgr.vue"),
                meta: {
                    layout: "vertical",
                    auth: (islogin, roles, permissions) => {
                        return islogin && (roles.includes("admin") || roles.includes("read_only"))
                    },
                },
            },
        ]
    },
    {
        header: 'Domain',
        items: [
            {
                name: 'domain',
                icon: 'BoxIcon',
                path: '/domain',
                component: () => import('../pages/domain/domainMgr.vue'),
                meta:{
                    layout:'vertical',
                    auth: (islogin, roles, permissions) => {
                        return islogin
                    }           
                }
            },
            {
                name: 'record',
                path: '/record/:domain/:domainId',
                menu_hidden: true,
                component: () => import('../pages/record/recordMgr.vue'),
                meta:{
                    layout:'vertical',
                    auth: (islogin, roles, permissions) => {
                        return islogin
                    }           
                }
            },
            {
                name: 'cert',
                icon: 'FileTextIcon',
                path: '/cert',
                component: () => import('../pages/cert/certMgr.vue'),
                meta:{
                    layout:'vertical',
                    auth: (islogin, roles, permissions) => {
                        return islogin
                    }           
                }
            }
        ]
    },

    {
        header: 'API',
        items: [
            {
                name: 'api reference',
                icon: 'BookOpenIcon',
                path: 'https://api.dns.coreservice.io/index.html',
                meta:{
                    layout:'vertical'         
                }
            },
        ]
    },


    //----------
    
]