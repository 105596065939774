import user from "./user"
import captcha from "./captcha"
import domain from "./domain"
import record from "./record"
import cert from "./cert"
import rule from "./rule"
import kv from "./kv"

export default {
    user,
    captcha,
    domain,
    record,
    cert,
    rule,
    kv
}