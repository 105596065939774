const passwordReg = /(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{6,20}$/; //must contain number and letter length 6-20
const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const hostedDomainReg = /^[^\.]+\.[^\.]+$/;
const certDomainReg = /^(?=^.{3,255}$)[a-zA-Z0-9*][-a-zA-Z0-9_]{0,62}(\.[a-zA-Z0-9_][-a-zA-Z0-9_]{0,62})+$/;


export function validatePassword(password) {
    return passwordReg.test(password);
}

export function validateEmail(email) {
    return emailReg.test(email);
}

export function validateHostedDomain(hostedDomain) {
    return hostedDomainReg.test(hostedDomain);
}

export function validateCertDomain(certDomain) {
    return certDomainReg.test(certDomain);
}


export default {
    validatePassword,
    validateEmail,
    validateHostedDomain,
    validateCertDomain
}
