import config from "../config/config";
import request from "./request";

async function getAuthConfig() {
    let url = config.api.endpoint + "/api/user/auth_config";
    return await request.Get(url);
}

async function login(email, password, captchaId, captcha) {
    let url = config.api.endpoint + "/api/user/login";
    return await request.Post(url, {
        email: email,
        password: password,
        captcha_id: captchaId,
        captcha: captcha
    });
}

async function addUser(email, password,roles,permissions,token) {
    let url = config.api.endpoint + "/api/user/add";
    return await request.Post(url, {
        email: email,
        password: password,
        forbidden:false,
        roles:roles,
        permissions:permissions,
    }, token);
}

async function updateUser(id,forbidden,roles,permissions,token) {
    let url = config.api.endpoint + "/api/user/update";
    return await request.Post(url, {
        filter:{
            id:[id],
        },
        update:{
            forbidden: forbidden,
            roles:roles,
            permissions:permissions,
        },
    }, token);
}

async function deleteUser(id,token){
    let url = config.api.endpoint + "/api/user/delete";
    return await request.Post(url, {
        filter:{
            id:[id],
        }
    }, token);
}

async function queryUser(emailPattern,userId,limit,offset,token){
    let url = config.api.endpoint + "/api/user/query";
    return await request.Post(url, {
        filter:{
            email_pattern: emailPattern===""?null:emailPattern,
            id:userId===0?null:userId,
        },
        limit: limit,
        offset: offset,
    },token);
}

async function getEmailVCode(email){
    let url = config.api.endpoint + "/api/user/email_vcode";
    return await request.Post(url, {
        email:email
    });
}

async function resetPassword(email,password,vcode){
    let url = config.api.endpoint + "/api/user/reset_password";
    return await request.Post(url, {
        email:email,
	    password:password,
	    vcode:vcode
    });
}

export default {
    getAuthConfig,
    login,
    addUser,
    updateUser,
    queryUser,
    deleteUser,
    getEmailVCode,
    resetPassword
};