import config from "../config/config";
import request from "./request";

async function addDomain(name, expiration, token) {
    let url = config.api.endpoint + "/api/domain/add";
    return await request.Post(
        url,
        {
            name: name,
            expiration_time: expiration,
        },
        token
    );
}

async function deleteDomain(id, token) {
    let url = config.api.endpoint + "/api/domain/delete";
    return await request.Post(
        url,
        {
            filter: {
                id: [id],
            },
        },
        token
    );
}

async function queryDomain(domainPattern, userId, domainId, limit, offset, token) {
    let url = config.api.endpoint + "/api/domain/query";
    return await request.Post(
        url,
        {
            filter: {
                id: domainId === 0 ? null : domainId,
                name_pattern: domainPattern === "" ? null : domainPattern,
                user_id: userId === 0 ? null : userId,
            },
            limit: limit,
            offset: offset,
        },
        token
    );
}

async function updateDomain(id, expiration, forbidden, token) {
    let url = config.api.endpoint + "/api/domain/update";
    return await request.Post(
        url,
        {
            filter: {
                id: [id],
            },
            update: {
                forbidden: forbidden,
                expiration_time: expiration,
            },
        },
        token
    );
}

export default {
    addDomain,
    deleteDomain,
    queryDomain,
    updateDomain,
};
