import config from "../config/config";
import request from "./request";

async function getCountryList(){
    let url = config.api.endpoint + "/api/country_list";
    return await request.Get(url);
}

async function addRule(recordId,continent,country,content,weight,token) {
    let url = config.api.endpoint + "/api/rule/add";
    return await request.Post(url, {
        record_id:recordId,
        continent_code:continent,
        country_code:country,
        destination:content,
        weight:weight
    }, token);
}

async function deleteRule(id,token){
    let url = config.api.endpoint + "/api/rule/delete";
    return await request.Post(url, {
        filter:{
            Id:[id]
        }
    }, token);
}

async function queryRules(recordId,token){
    let url = config.api.endpoint + "/api/rule/query";
    return await request.Post(url, {
        filter:{
            record_id:recordId
        },
    },token);
}

async function updateRule(id,continent,country,content,weight,token){
    let url = config.api.endpoint + "/api/rule/update";
    return await request.Post(url, {
        filter:{
            id:[id]
        },
        update:{
            continent_code:continent,
            country_code:country,
            destination:content,
            weight:weight
        },
    },token);
}

export default {
    addRule,
    deleteRule,
    queryRules,
    updateRule,
    getCountryList
};